import { apiData } from "utils/api";
const {REACT_APP_NEW_CRM}= process.env

export const getAllLeads=async(page,data)=>{

  const req = {
    url: REACT_APP_NEW_CRM+`/lead/all_leads?page=${page}`,
    method: "POSt",
    data: data,
  };
  return await apiData(req)
}
export const getleadFilter =async(data)=>{
 const req = {
  url:REACT_APP_NEW_CRM+`/lead/lead_filters`,
  method:"POST",
  data:data
 }
 return await apiData(req)
}