import React, { useState } from "react";
import { Nav } from "rsuite";
import { Panel, Input, InputGroup } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import UserInfo from "../Customers/UserInfo/UserInfo";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
// import LoanApplication from "./LoanApplication";
// import Collection from "./Collection";
// import Documents from "./Documents";
// import Communications from "./Communication";
// import HistoricalData from "./HistoricalData";
// import Timeline from "./TimeLine";
// import ApiLogs from "./ApiLogs";
import { Loader } from "components";

const NavList = [
  "Loan Application",
  "Collection",
  "Documents",
  "Communications",
  "Historical Data",
  "Timeline",
  "API logs",
];
export default function CustomerDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  console.log("tab", tab);
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {/* <Panel bordered className="bg-white">
        <div className="flex">
          <div>
            <InputGroup>
              <Input />
              <InputGroup.Addon>
                <SearchIcon />
              </InputGroup.Addon>
            </InputGroup>
          </div>
        </div>
      </Panel> */}
      <br />
      <UserInfo />
      <br />
      <Nav
        appearance="pills"
        style={{ background: "#3C81F61A", width: "100%" }}
        justified
        defaultActiveKey="Loan Application"
        onSelect={(e) => {
          navigate(`/lead_customer_profile/447595?tab=${e}`);
        }}
        activeKey={tab}
      >
        {NavList.map((item, index) => (
          <Nav.Item key={index} eventKey={item}>
            {item}
          </Nav.Item>
        ))}
      </Nav>
      <br />
      {/* {(tab === "Loan Application" || tab === null) && <LoanApplication />}
      {tab === "Collection" && <Collection />}
      {tab === "Documents" && <Documents />}
      {tab === "Communications" && <Communications />}
      {tab === "Historical Data" && <HistoricalData />}
      {tab === "Timeline" && <Timeline />}
      {tab === "API logs" && <ApiLogs />} */}
    </div>
  );
}
