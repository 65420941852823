import React, { useEffect, useState } from "react";
import Layout from "./layout/Layout";
import Cookies from "js-cookie";
import { storeToken } from "./utils/storage";

export default function App() {
  useEffect(() => {
    handlecCookies();
  }, []);

  const handlecCookies = () => {
    const token =
      "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJfaWQiOjgyMywiaXNwIjoxNzMyNjA1MjA2LCJleHAiOjE3MzM5MDEyMDZ9.E0R3DhLzWzYY9XBnA6A9E7RiThORNESiomfODwbfJUTtKKMIS5yX5wZ-F0CbUne4s9c2sG4PZmqetoevVcc_vEkY-BJ1cqqoNwimBspsHhPJsGwaTnjM8cY--lbZOOtOttLseTZTCadBdbdLt73LCWj2uzQ7gFp2_Bxt-EHqGWkS9dwdnsRnsmx0gafo5uMAykciadX8fLsFdfv9HvW_FZPsCAjcqs8D-yBMP4VmkH4aREALFI2MKFOW-JzOQZZR0gFW2WAmL51dsycGjkmT-JF_2bPCe9P-ZZmBEed4DUjxKq7s92c3fk42RQ91YN7xjS6S9lFwHcduxQCbKLIKTw";
    // const token = Cookies.get("jwt_token");
    const hasReloaded = localStorage.getItem("hasReloaded");

    if (token && !hasReloaded) {
      storeToken(token);
      localStorage.setItem("hasReloaded", "true");
      window.location.reload();
    }
  };

  return (
    <>
      <Layout />
    </>
  );
}
