// Storage.js

const storeToken = (token) => {
  try {
    localStorage.setItem("token", token);
  } catch (error) {
    console.log("Error getting in store token!", error);
  }
};

const getToken = () => {
  try {
    return localStorage.getItem("token");
  } catch (error) {
    console.log("Error getting in token", error);
  }
};

export { storeToken, getToken };
