import React from "react";
import styles from "./CreditLeads.module.scss";

export default function CreditLeads() {
  return (
    <div>
      <p>Credit Leads</p>
    </div>
  );
}
