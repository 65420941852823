
  import React, { useEffect, useState } from "react";
  import "../AllLeads/AllLeads.scss";
  import { getAllLeads } from "services/LeadsAPI";
  import { Loader } from "rsuite";
  import {Panel,Table } from "rsuite";
  import ResponsivePagination from "react-responsive-pagination";
  import "react-responsive-pagination/themes/classic.css";
  import { FilterComponent } from "../Filter";
  
  export default function HotLeads() {
    const { Column, HeaderCell, Cell } = Table;
  
  
  
    const [data, setData] = useState([{ lead_id: "984849", name: "Pramod", email: "prmaod@ramfincorp.com", mobile_number: "8959747704", lead_status: "Verified" }])
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(100);
    const [totalItems, setTotalItems] = useState(1000);
  
  
  
    const fetchAllLeads = async (filter) => {
      const reqBody = {
        pageName: "Hot Leads",
        ...filter
      }
      const response = await getAllLeads(currentPage, reqBody)
  
      setData(response?.data)
      setLoading(false)
    }
  
  
  
    useEffect(() => {
      fetchAllLeads()
  
    }, [currentPage])
  
  
  const handleStatusCss =(value)=>{
  if(value==="Blacklisted"||value==="Rejected"){
    return "danger"
  }
  else if(value==="Document Received"||value==="Fresh Lead"){
    return "success"
  }
  else{
    return ""
  }
  }
    if (loading) {
      return <Loader />;
    }
    return (
      <div>
  
  
     <FilterComponent fetchAllLeads={fetchAllLeads} pageName="Hot Leads"/>
        <br />
        <Panel bordered className="bg-white">
          <div >
            <Table  autoHeight={true} data={data} loading={loading} style={{ width: "100%" }}>
              <Column flexGrow={1}>
                <HeaderCell>Lead ID</HeaderCell>
                <Cell dataKey="leadID" />
              </Column>
  
              <Column flexGrow={1}>
                <HeaderCell>Name</HeaderCell>
                <Cell dataKey="Name" />
              </Column>
  
              <Column align="center" flexGrow={1}>
                <HeaderCell>Email</HeaderCell>
  
                <Cell dataKey="EMail" />
              </Column>
  
              <Column align="center" flexGrow={1}>
                <HeaderCell>Mobile Number</HeaderCell>
                <Cell dataKey="Mobile" />
              </Column>
  
  
  
              <Column align="center" flexGrow={1}>
                <HeaderCell>Lead Status</HeaderCell>
                <Cell>{(rowData) => <span className={handleStatusCss(rowData?.Status)}>{rowData?.Status}</span>}</Cell>
  
              </Column>
  
              <Column align="center" flexGrow={1}>
                <HeaderCell>Allocated To</HeaderCell>
                <Cell>{(rowData) => rowData?.lead_status||"NA"}</Cell>
  
              </Column>
              <Column align="center" flexGrow={1}>
                <HeaderCell>Action</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <button className="btn-primary">
                      Allocate to me
                    </button>
                  )}
                </Cell>
  
              </Column>
  
  
            </Table>
          </div>
        </Panel>
  
        <div className="footer">
          <div>Total Records: {totalItems}</div>
          <div>
            <ResponsivePagination current={currentPage} total={totalPages} onPageChange={setCurrentPage} />
          </div>
        </div>
  
  
      </div>
    );
  }
  