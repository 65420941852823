import { Button, Input, Panel } from "rsuite";
import { Divider } from "rsuite";
import { Grid, Row, Col } from "rsuite";
import "./userInfo.scss";
import { LuUser2 } from "react-icons/lu";
import rightic from "../../../assests/mdi_tick.png";
import moment from "moment";
export default function UserInfo() {
  return (
    <>
      <Panel bordered className="bg-white">
        <Grid fluid>
          <Row className="show-grid align-center">
            <Col xs={5}>
              {" "}
              <span>Pradeep Kumar Dalal </span>
              <div disabled className="user_id bg_gray">
                <LuUser2 /> ID: 892894
              </div>
            </Col>
            <Col xs={7}>
              <div className="bg_gray block flex-col align-center adhar">
                <span>XXXXXXXX988990</span>
                <Row>
                  <Col>
                    <p>Aadhaar Number</p>
                  </Col>
                  <Col>
                    <Button
                      appearance="ghost"
                      size="sm"
                      style={{
                        background: "#fff",
                        marginLeft: "40px",
                        height: "28px",
                      }}
                    >
                      Verify
                    </Button>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={6}>
              <Row>
                <div className="bg_gray flex-col adhar">
                  <span>CVRDV956W</span>
                  <Row>
                    <Col>
                      <p>PAN Number</p>
                    </Col>
                    <Col>
                      <img src={rightic} width={25} height={40} />{" "}
                    </Col>
                  </Row>
                </div>
              </Row>
            </Col>
            <Col xs={6}>
              <button className="btn-approved">Approved Process</button>
            </Col>
          </Row>
        </Grid>
      </Panel>
      <Panel bordered className="bg-white">
        <Grid fluid>
          <Row className="show-grid align-center">
            <Col xs={8}>
              <span>DOB : {moment().format("LL")}</span>
            </Col>
            <Col xs={8}>
              <span>Number :(+91) 6367423398</span>
            </Col>
            <Col xs={8}>
              <span>Email : Pardeepkumar@gmail.com</span>
            </Col>
          </Row>
        </Grid>
      </Panel>
    </>
  );
}
