import React, { useEffect } from "react";
// import Loader from "../components/Loader/Loader";

import { useNavigate } from "react-router-dom";
import { storeToken } from "../../utils/storage";

export default function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    const token = queryParams.get("token");

    if (token) {
      storeToken(token);
      navigate("/");
    }
  }, []);

  return <div>{/* <Loader /> */}</div>;
}
