// Menu
export const menu = [
  {
    name: "Dashboard",
    icon: "MdDashboard",
    link: "/",
  },

  {
    name: "Management",
    icon: "MdGroup",
    link: "/management",
    subMenu: [
      {
        text: "User List",
        link: "/user-list",
      },
      {
        text: "Login Logs",
        link: "/login-logs",
      },
      {
        text: "IP List",
        link: "/whitelistip",
      },
      {
        text: "Repay Date Holiday",
        link: "/repay-date-holiday",
      },
      {
        text: "Send Whatsapp Status",
        link: "/send-whatsapp-status",
      },
      {
        text: "Auto Disbursal Status",
        link: "/auto-disbursal-status",
      },
      {
        text: "Change Repay Date",
        link: "/change-repay-date",
      },
      {
        text: "Name Mismatch Data",
        link: "/name-mismatch-date",
      },
      {
        text: "Payment Mode Change",
        link: "/payment-mode-change",
      },
    ],
  },

  {
    name: "Collection",
    icon: "MdSettings",
    link: "/collection",
    subMenu: [
      {
        text: "Payday Payment Pending",
        link: "/payday-payment-pending",
      },
      {
        text: "Closed",
        link: "/closed",
      },
      {
        text: "Part Payment",
        link: "/part-payment",
      },
      {
        text: "Waive Off",
        link: "/waive-off",
      },
      {
        text: "Settlement",
        link: "/settlement",
      },
      {
        text: "Bulk E-Mandate",
        link: "/bulk-emandate",
      },
      {
        text: "Request Refund",
        link: "/request-refund",
      },
      {
        text: "Refund List",
        link: "/refund-list",
      },
    ],
  },

  {
    name: "Collection Manager",
    icon: "MdOutlineStackedBarChart",
    link: "/collection-manager",
    subMenu: [
      {
        text: "Collection For Approval",
        link: "/collection-for-approval",
      },
      {
        text: "Collection Approved",
        link: "/collection-approved",
      },
      {
        text: "Collection Rejected",
        link: "/collection-rejected",
      },
      {
        text: "Online Payment",
        link: "/online-payment",
      },
    ],
  },

  {
    name: "Reports",
    icon: "MdOutlineStackedBarChart",
    link: "/reports",
    subMenu: [
      {
        text: "All Lead Reports",
        link: "/all-lead-reports",
      },
      {
        text: "Quick Reports",
        link: "/quick-reports",
      },
      {
        text: "Calling Report",
        link: "/calling-report",
      },
      {
        text: "Credit Report",
        link: "/credit-report",
      },
      {
        text: "Collection Report",
        link: "/collection-report",
      },
      {
        text: "Date Wise Collection Report",
        link: "/date-wise-collection-report",
      },
      {
        text: "Tellecaller Collection Report",
        link: "/tellecaller-collection-report",
      },
      {
        text: "Date Wise Lead Report",
        link: "/date-wise-lead-report",
      },
      {
        text: "Track Agent Performance",
        link: "/track-agent-performance",
      },
      {
        text: "App Disbursal Data",
        link: "/app-disbursal-data",
      },
      {
        text: "Datewise Payday Pending Report",
        link: "/datewise-payday-pending-report",
      },
      {
        text: "Customer Feedback",
        link: "/customer-feedback",
      },
      {
        text: "Agent Performance Report",
        link: "/agent-performance-report",
      },
      {
        text: "Refund Report",
        link: "/refund-report",
      },
      {
        text: "App Issues",
        link: "/app-issues",
      },
      {
        text: "Disbursed Data",
        link: "/disbursed-data",
      },
      {
        text: "Collection Data",
        link: "/collection-data",
      },
      {
        text: "Landing Partner Leads",
        link: "/landing-partner-leads",
      },
    ],
  },

  {
    name: "Tech Management",
    icon: "MdOutlineStackedBarChart",
    link: "/tech-management",
    subMenu: [
      {
        text: "Roles List",
        link: "/roles-list",
      },
      {
        text: "Permissions List",
        link: "/permissions-list",
      },
    ],
  },

  {
    name: "Customers",
    icon: "MdDashboard",
    link: "/customers",
  },

  {
    name: "Request Callback",
    icon: "MdDashboard",
    link: "/request-callback",
  },

  {
    name: "Field Officer",
    icon: "MdOutlineStackedBarChart",
    link: "/field-officer",
    subMenu: [
      {
        text: "Field Officer List",
        link: "/field-officer-list",
      },
      {
        text: "Field Officer Work",
        link: "/field-officer-work",
      },
      {
        text: "Notification",
        link: "/notification",
      },
    ],
  },

  {
    name: "Leads",
    icon: "MdOutlineStackedBarChart",
    link: "/leads",
    subMenu: [
      {
        text: "All Leads",
        link: "/all-leads",
      },
      {
        text: "Hot Leads",
        link: "/hot-leads",
      },
      {
        text: "Credit Leads",
        link: "/credit-leads",
      },
      {
        text: " Sanction Leads",
        link: "/sanction-leads",
      },
    ],
  },
];
