import axios from "axios";
import { getToken } from "./storage";




export async function apiData(req, contentType = "application/json") {
  const { url, method, data } = req;

  try {
    const res = await axios({
      url:   url,
      method: method,
      data: data,
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return res?.data;
  } catch (error) {
    // if (error.response && error.response.status === 403) {
    //   window.location.href = "/logout";
    // }
    console.log("Failed to fetch data", error);
    return error?.response?.data;
  }
}
