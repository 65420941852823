import React, { useState } from "react";
import  "./layout.scss";
import { Container, Header, Sidebar, Sidenav, Content, Nav, Breadcrumb, IconButton, HStack, Stack, Text } from "rsuite";
import Router from "../routes/Router";
import { Icon } from "@rsuite/icons";
import { FaReact } from "react-icons/fa";
import {
  MdDashboard,
  MdGroup,
  MdSettings,
  MdOutlineStackedBarChart,
  MdKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { Navbar } from "../components";

export default function Layout(props) {
  const [expand, setExpand] = useState(true);

  const windowHeight = window.innerHeight;

  return (
  <>
    <Container>
      <Sidebar
        className="sidebar"
        style={{ display: "flex", flexDirection: "column" }}
        width={expand ? 256 : 56}
        collapsible
      >
        <Sidenav.Header>
          <Brand expand={expand} />
        </Sidenav.Header>

        <div className="sidebar__body" style={{ height: windowHeight }}>
          <Sidenav expanded={expand} defaultOpenKeys={["/"]} appearance="inverse">
            <Sidenav.Body>
              <Navbar />
            </Sidenav.Body>
          </Sidenav>
        </div>

        {/* <NavToggle expand={expand} onChange={() => setExpand(!expand)} /> */}
      </Sidebar>
      <Content style={{ marginLeft: 256, padding: 15 }}>
        <Header className="top__header">ॐ नमो भगवते वासुदेवाय नमः</Header>
        <Router />
      </Content>
    </Container>
    </>
  );
}

const NavToggle = ({ expand, onChange }) => {
  return (
    <Stack className="nav-toggle" justifyContent={expand ? "flex-end" : "center"}>
      <IconButton
        onClick={onChange}
        appearance="subtle"
        size="lg"
        icon={expand ? <MdKeyboardArrowLeft /> : <MdOutlineKeyboardArrowRight />}
      />
    </Stack>
  );
};

const Brand = ({ expand }) => {
  return (
    <HStack className="page-brand" spacing={12}>
      {/* <FaReact size={26} />
      {expand && <Text>Brand</Text>} */}
      <div
        style={{
          padding: "20px 12px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="/images/logo.png" width="160" alt="" />
      </div>
    </HStack>
  );
};
