import { apiData } from "../utils/api";
import Swal from "sweetalert2";
const { REACT_APP_API_URL } = process.env;

export const uploadFileAPI = async (file) => {
  const formData = new FormData();
  formData.append("image", file);

  const req = {
    url: REACT_APP_API_URL + "/crm/uploadBulkMandateFile",
    method: "POST",
    data: formData,
  };

  return await apiData(req, "multipart/form-data");
};

export const getUploadFileAPI = async (page, limit) => {
  const req = {
    url: REACT_APP_API_URL + `/crm/getBulkMandateData?page=${page}&limit=${limit}`,
    method: "GET",
    data: "",
  };

  return await apiData(req);
};

export const getFileDownloadURLAPI = async (key) => {
  const req = {
    url: REACT_APP_API_URL + `/crm/getUrlforBulkMandateFile?fileName=${key}`,
    method: "GET",
    data: "",
  };

  return await apiData(req);
};
