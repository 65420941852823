import React from "react";
import styles from "./SanctionLeads.module.scss";

export default function SanctionLeads() {
  return (
    <div>
      <p>Sanction Leads</p>
    </div>
  );
}
