import { useEffect, useState } from "react";
import { getleadFilter } from "services/LeadsAPI";
import { Button, Panel, SelectPicker } from "rsuite";

const filterField = {
  device: null,
  allocated: null,
  caseType: null,
  employeeType: null,
  status: null
}


export const FilterComponent = ({ fetchAllLeads, pageName }) => {


  const [filterData, setFilterData] = useState([])
  const [filter, setFilter] = useState({})

  const fetchLeadFilter = async () => {
    const reqBody = {
      pageName: pageName
    }
    const response = await getleadFilter(reqBody)
    setFilterData(response?.data)

  }

  const formatSelectType = (value) => {

    const result = value?.map(item => ({ label: item, value: item }))
    return result;
  }

  const formatAllocatedTo = (value) => {

    const result = value?.map(item => ({ label: item?.name, value: item?.userID }))
    return result;
  }

  const handleFilter = (name, value) => {
    setFilter({ ...filter, [name]: value })
  }




  useEffect(() => {
    fetchLeadFilter()
  }, [])

  return (
    <Panel header="All Leads" bordered className="bg-white" >
      <div style={{ overflowX: "auto" }}>
        <div className="space-between">
          <SelectPicker

            className="ali"

            value={filter?.status}
            data={formatSelectType(filterData?.status)}
            searchable={false}
            style={{ width: "45%" }}
            placeholder="Status"
            onChange={(e) => handleFilter("status", e)}
          />
          <SelectPicker


            value={filter?.caseType}
            data={formatSelectType(filterData?.caseType)}
            searchable={false}
            style={{ width: "45%" }}
            placeholder="Case"
            onChange={(e) => handleFilter("caseType", e)}
          />
        </div>
        <br /><br />
        <div className="space-between">
          <SelectPicker


            value={filter?.employeeType}
            data={formatSelectType(filterData?.employeeType)}
            searchable={false}
            style={{ width: "30%" }}
            placeholder="Employment Type"
            onChange={(e) => handleFilter("employeeType", e)}
          />
          <SelectPicker

            value={filter?.device}

            data={formatSelectType(filterData?.device)}
            searchable={false}
            style={{ width: "30%" }}
            placeholder="Device"
            onChange={(e) => handleFilter("device", e)}

          />
          <SelectPicker
            value={filter?.allocated}
            data={formatAllocatedTo(filterData?.allocated)}
            searchable={false}
            style={{ width: "30%" }}
            placeholder="Allocated To"
            onChange={(e) => handleFilter("allocated", e)}

          />
        </div>
        <br />
        <br />
        <div className="align-right">
          <Button className="btn-delete m-10" onClick={() => {


            setFilter({ ...filterField })
          }}>
            Clear Filter
          </Button>
          <Button className="btn-primary mltb-10" onClick={() => fetchAllLeads(filter)}>
            Search
          </Button>
        </div>
      </div>



    </Panel>
  )
}