import React, { useState } from "react";
import { Nav } from "rsuite";
import {
  MdDashboard,
  MdGroup,
  MdSettings,
  MdOutlineStackedBarChart,
  MdKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { Icon } from "@rsuite/icons";
import { Link, useLocation } from "react-router-dom";
import { menu } from "../../constants/menu";

export default function Navbar() {
  const [expandedKey, setExpandedKey] = useState(null);
  const location = useLocation();

  console.log("pathname-------", location.pathname);

  const iconRegistry = {
    MdDashboard,
    MdGroup,
    MdSettings,
    MdOutlineStackedBarChart,
    MdKeyboardArrowLeft,
    MdOutlineKeyboardArrowRight,
  };

  return (
    <Nav defaultActiveKey="/" activeKey={location.pathname} onSelect={(eventKey, event) => console.log(eventKey)}>
      {menu.map((item, index) => {
        const IconComponent = iconRegistry[item.icon];
        return (
          <React.Fragment key={index}>
            {!item.hasOwnProperty("subMenu") ? (
              <Nav.Item as={Link} to={item.link} eventKey={item.link} icon={<Icon as={IconComponent} />}>
                {item.name}
              </Nav.Item>
            ) : (
              <Nav.Menu
                eventKey={item.link}
                trigger="hover"
                title={item.name}
                icon={<Icon as={IconComponent} />}
                placement="rightStart"
              >
                {item.subMenu.map((data, index) => (
                  <Nav.Item as={Link} to={data.link} eventKey={data.link} key={data.text}>
                    {data.text}
                  </Nav.Item>
                ))}
              </Nav.Menu>
            )}
          </React.Fragment>
        );
      })}

      <br />
      <br />
      <br />
      <br />
    </Nav>
  );
}
